// src/axios.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://starai.tw', // 设置基础 URL
  timeout: 30000, // 设置超时时间 30s 后取消请求
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    // 在发送请求前可以添加通用逻辑，比如添加 token
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data; // 直接返回响应数据
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
